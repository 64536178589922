// Variables
$black: #313131;

// Mixins
@mixin transition ($duration, $easing, $delay) {
    -webkit-transition: all $duration $easing $delay;
    -moz-transition: all $duration $easing $delay;
    -o-transition: all $duration $easing $delay;
    -ms-transition: all $duration $easing $delay;
    transition: all $duration $easing $delay;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin animation($animation) {
    -webkit-animation: #{$animation};
    -moz-animation: #{$animation};
    -ms-animation: #{$animation};
    animation: #{$animation};
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

@include keyframes(bounce) {
    0%,
    20%,
    50%,
    80%,
    100% {
        @include transform(translateY(0));
    }
    40% {
        @include transform(translateY(-20px));
    }
    60% {
        @include transform(translateY(-10px));
    }
}
