// Global Styles
html, body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
    font-weight: 700;
    letter-spacing: 1px;
    margin-top: 0;
}

h1 {
    font-size: 3.6rem;

    @media (min-width: $screen-sm-min) {
        font-size: 5.5rem;
    }
}

h2 {
    font-size: 3rem;

    @media (min-width: $screen-sm-min) {
        font-size: 5rem;
    } 
}

h3 {
    font-size: 2.6rem;

    @media (min-width: $screen-sm-min) {
        font-size: 4.5rem;
    }       
}

h4 {
    font-size: 2rem;

    @media (min-width: $screen-sm-min) {
        font-size: 4rem;
    }      
}

a {
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
        color: inherit;
    }
}

nav {
    text-transform: uppercase;

    a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: inherit;
            text-decoration: none;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            margin: 0 15px;
        }
    }

}

section {
    padding: 40px 0;
    text-align: center;

    @media (min-width: $screen-sm-min) {
        padding: 75px 0;
    }

    @media (min-width: $screen-md-min) {
        padding: 100px 0;
    }

    p {
        font-size: 1.6rem;
    }

    &:not(.products) a {
        font-weight: bold;
    }
}

.logo {
    max-width: 100px;
    z-index: 5;
    cursor: pointer;

    @media (min-width: $screen-sm-min) {
        max-width: 125px;
    }

    @media (min-width: $screen-md-min) {
        max-width: 150px;
    }
    @media (min-width: $screen-lg-min) {
        max-width: 175px;
    }    
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

    @media (min-width: 768px) {
        width: 750px;
    }

    @media (min-width: 992px) {
        width: 970px;
    }

    @media (min-width: 1200px) {
        width: 1170px;
    }
}

.visible-mobile {
    display: none;

    @media (max-width: $screen-xs-max) {
        display: block;
    }
}

.hidden-mobile {
    display: block;
    
    @media (max-width: $screen-xs-max) {
        display: none;
    }
}

// Header + Nav
.header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;

    .logo {
        position: absolute;
        top: 0;
        left: 15px;
        z-index: 1;
    }

    .nav-overlay {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        background-color: black;
        opacity: 0.5;

        &.open {
            display: block;
        }
    }

    .nav-main {

        a {
            font-weight: bold;
            white-space: nowrap;
        }

        @media (max-width: $screen-xs-max) {
            position: fixed;
            z-index: 4;
            top: 50px;
            right: 0;

            li {
                margin: 10px 0;
                @include transform(translateX(101%));

                &:nth-child(1) {
                    @include transition(0.3s, ease-in-out, 0s);
                }

                &:nth-child(2) {
                    @include transition(0.3s, ease-in-out, 0.1s);
                }

                &:nth-child(3) {
                    @include transition(0.3s, ease-in-out, 0.2s);
                }                
            }

            a {
                display: block;
                padding: 5px 10px;
            }

            &.open li {
                @include transform(translateX(0%));
            }
        }

        @media (min-width: $screen-sm-min) {

            li {
                display: inline-block;
            }

            a {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: -5px;
                    left: 0;
                    visibility: hidden;
                    @include transform(scaleX(0));
                    @include transition(0.3s, ease-in-out, 0s);
                }

                &:hover:before {
                    visibility: visible;
                    @include transform(scaleX(1));
                }
            }
        }
    }

    .nav-mobile {
        position: relative;
        z-index: 4;
        overflow: hidden;
        margin: 0 5px 0 0;
        padding: 0;
        width: 40px;
        height: 40px;
        text-indent: -9999px;
        border: none;
        background-color: transparent;
        transition: background 0.3s;

        &:focus {
            outline: none;
        }

        span {
            display: block;
            position: absolute;
            top: 18px;
            left: 5px;
            right: 5px;  
            height: 4px;
            border-radius: 3px;
            transition: background 0s 0.3s;

            &:before,
            &:after {
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 4px;
                content: "";
                border-radius: 3px;
                transition-duration: 0.3s, 0.3s;
                transition-delay: 0.3s, 0s;
            }

            &:before {
                top: -9px;
                transition-property: top, transform;
            }

            &:after {
                bottom: -9px;
                transition-property: bottom, transform;
            }
        }

        &.open {
            span {
                background: none;

                &:before,
                &:after {
                    transition-delay: 0s, 0.3s;
                }

                &:before {
                    top: 0;
                    transform: rotate(45deg);
                }

                &:after {
                    bottom: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

// Hero
.hero {
    position: relative;

    img {
        width: 100%;
    }

    .down {
        position: absolute;
        width: 40px;
        height: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        margin-right: auto;
        margin-left: auto;
        cursor: pointer;
        text-align: center;

        &.bounce {
            @include animation(bounce 2s infinite);
        }
    }

    .glyphicon {
        font-size: 20px;
        text-shadow: 0 0 10px rgba($black, 0.5);
    }
}

// Product Section
.products {
    h3 {
        text-align: left;
        text-transform: uppercase;

        @media (min-width: $screen-sm-min) {
            font-size: 3rem;
        }
    }
}
.product-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .product {
        width: 50%;
        padding: 15px;
        text-align: center;

        @media (min-width: $screen-sm-min) {
            width: 33%;
        }

        @media (min-width: $screen-md-min) {
            width: 25%;
        }

        @media (min-width: $screen-lg-min) {
            width: 20%;
        }

        a {
            color: $black;
            text-decoration: none;
        }

        img {
            padding: 0 5%;
            margin-bottom: 15px;
            width: 100%;
            max-width: 150px;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            @media (min-width: $screen-md-min) {
                @include transition(0.5s, ease-in-out, 0s);
                @include transform(translateZ(0) scale(1, 1));

                &:hover {
                    @include transition(0.5s, ease-in-out, 0s);
                    @include transform(translateZ(0) scale(1.1, 1.1));
                }
            }
        }

        span {
            display: block;
            margin: 0 auto;
            max-width: 175px;
        }
    }
}

// History
#history {
    p {
        text-align: left;
        margin: 0;
        padding: 8px 0;
    }

    img {
        width: 100%;
        max-width: 300px;
        height: auto;
    }
}

.history {
    max-height: 200px;
    position: relative;
    overflow: hidden;
}
.history .read-more {
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: 100%; 
    text-align: center !important;
    margin: 0; padding: 30px 0;

    /* "transparent" only works here because == rgba(0,0,0,0) */
    background-image: -moz-linear-gradient(top, rgba(255,0,0,0), rgba(255,0,0,100));
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, rgba(255,0,0,0)),color-stop(1, rgba(255,0,0,100)));

    a {
        background-color: #ffffff;
        padding: 10px 50px;
        color: red;
        text-decoration: none;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px; /* future proofing */
        -khtml-border-radius: 10px; /* for old Konqueror browsers */
    }
}

// Footer
#footer {
    text-align: center;

    .logo {
        margin: 15% auto 25%;

        @media (min-width: $screen-sm-min) {
            margin: 15% auto;
        }
    }

    li {
        display: inline-block;
        font-size: 12px;
        font-weight: bold;

        @media (max-width: $screen-sm-max) {
            margin: 0 15px 10px 15px;
        }
    }

    .credit {
        margin: 25px 0;
        font-size: 12px;
        font-weight: bold;
    }
}

// Legal
.legal-modal {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }
}

// 404
.page-404 {
    .nav-mobile,
    .nav-overlay,
    .nav-main {
        display: none;
    }
}
.content-404 {
    padding-top: 25%;
    text-align: center;        
}
